<template>
	<div class="wrc">
		<div class="title_header_body">
			<div class="title_header flex">
				<div class="video_tabs pointer_s" v-for="(item,index) in videoList" :class="videoIndex==index ? '' : 'unchoose'"
						 :key="index" @click="videoClick(index)">
					{{ item.name }}
				</div>
			</div>
		</div>
		<div class="wrc-content flex-wrap">
			<div class="item flex-direction-column" v-for="(item, index) in list.data" :key="item.id"
					 @click="itemClick(item)">
				<img class="pic" :src="item.image" v-if="!videoIndex"/>
				<el-image
					v-else
					class="pic"
					fit="cover"
					:src="item.src"
					:preview-src-list="list.dataC">
				</el-image>
				<div class="mask flex-center" v-if="!videoIndex">
					<img src="@/assets/image/icon/11.png"/>
				</div>
				<span class="one-line">{{ item.name }}</span>
				<div class="desc flex">
					<img src="@/assets/image/icon/10.png"/>
					<span>{{ item.create_time }}</span>
				</div>
			</div>
		</div>
		<Lack
			v-if="list.data?.length == 0"
			text="暂无内容"
			minHeight="260px"
			imgWidth="102px"
			imgHeight="68px"
			:imgSrc="require('@/assets/image/img/15.png')"
		></Lack>
		<div class="pagination">
			<el-pagination
				@current-change="currentChange"
				background
				layout="prev, pager, next"
				:page-size="list.per_page"
				:total="list.total"
				:current-page="list.current_page"
			>
			</el-pagination>
		</div>


		<el-dialog
			:title="videoUrl.name"
			:visible.sync="dialogVisible"
			width="800px"
			:append-to-body="true">
			<!--			<video v-if="dialogVisible" style="width: 760px" :src="videoUrl.url" :poster="videoUrl.image" loop controls></video>-->

			<video v-if="dialogVisible" style="width: 760px" :src="videoUrl.url" loop controls></video>
			<!--			videoUrl-->
		</el-dialog>
	</div>
</template>

<script>
import Lack from '@/components/lack/lack.vue'
import {postVideoList, postAlbum} from '@/service/api/index'

export default {
	components: {Lack},
	data() {
		return {
			videoUrl: {
				url: '',
				image: ''
			},
			dialogVisible: false,
			videoList: [
				{
					name: '视频',
					id: 1,
					choose: true,
				}, {
					name: '图片',
					id: 2,
					choose: false,
				}
			],
			videoIndex: 0,
			list: {
				data: [],
				dataC: [],
				per_page: 15,
				total: 0,
				current_page: 1
			}
		}
	},

	mounted() {
		if (this.$route.params.info) {
			sessionStorage.setItem('params', this.$route.params.info)
			this.videoIndex = JSON.parse(this.$route.params.info).videoIndex
		} else {
			this.videoIndex = JSON.parse(sessionStorage.getItem('params')).videoIndex
		}
		this.postVideoList(1)
	},

	methods: {
		videoClick(index) {
			this.videoIndex = index
			this.postVideoList(1)
		},
		postVideoList(page) {
			this.list = {
				data: [],
				dataC: [],
				per_page: 15,
				total: 0,
				current_page: 1
			}
			if (this.videoIndex == 0) {
				postVideoList({page}).then((res) => {
					this.list = res.msg
				})
			} else {
				postAlbum({page}).then((res) => {
					this.list = res.msg
					this.list.dataC = res.msg.data.map(item => {
						return item.src
					})
					console.log(this.list.dataC)
				})
			}
		},
		itemClick(item) {
			if (this.videoIndex == 0) {
				this.dialogVisible = true
				this.videoUrl = item
				// this.$router.replace({
				// 	path: '/vdetail',
				// 	params: {
				// 		info: JSON.stringify(item)
				// 	}
				// })
			} else {
				// this.$router.replace({
				// 	path: '/pdetail',
				// 	params: {
				// 		info: JSON.stringify(item)
				// 	}
				// })
			}
		},
		currentChange(page) {
			this.postVideoList(page)
		}
	}
}
</script>

<style lang="less" scoped>
.title_header_body {
	padding: 0 30px;

	.title_header {
		border-bottom: 5px solid #212F82;
		//padding: 0 30px;
		.video_tabs {
			width: 108px;
			height: 45px;
			background: #212F82;
			font-weight: 500;
			font-size: 20px;
			color: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.unchoose {
			background: none;
			color: #333333;
		}
	}
}

@import url('~@/assets/css/pictureANDvideo.less');

/deep/ .el-pager li {
	font-weight: normal;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #212F82;
	color: #fff;
}

.pagination {
	display: flex;
	justify-content: center;
	margin-top: 29px;
}
</style>
